<template>
  <div class="service">
    <div>
      <table border="1" cellspacing="0" bordercolor="#ddd">
        <thead>
          <th v-for="(items,indexs) in titleList" :key="indexs" class="th">{{ items}}</th>
        </thead>
        <tbody>
          <tr v-for="(items,indexs) in outpatientList" :key="indexs">
            <td class="td" @click="goServce(items.doctor_info_id,items.doctor_name)">{{ items.doctor_name }}</td>
            <td v-for="(items2,indexs2) in items.week" :key="indexs2" class="td mz-td">
              <el-checkbox v-model="items2.is_select" @change="changeCheckbox($event,items2)" disabled />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { doctorArrangeList } from "../../api/api";
import { GetTitleList } from "../../api/api";
export default {
  data() {
    return {
      titleList: ["", "周一", "周二", "周三", "周四", "周五", "周六", "周日"],
      outpatientList: [],
    };
  },
  created() {
    this.getInfo();
    this.getTitle();
  },
  methods: {
    getInfo() {
      this.$axios.post(doctorArrangeList, {}).then((res) => {
        if (res.data.code == "1") {
          this.outpatientList = res.data.data;
        }
      });
    },
    getTitle() {
      this.$axios.post(GetTitleList, {}).then((res) => {
        if (res.data.code == "1") {
          this.titleList = res.data.data;
        }
      });
    },
    goServce(id, name) {
      this.$router.push({
        name: "mzrxq",
        query: { doctor_info_id: id, doctor_name: name },
      });
    },
    changeCheckbox(item) {
      console.log(item);
    },
  },
};
</script>
<style lang="scss" scoped>
.service {
  padding: 20px;
}
table {
  // border: 1px solid #ddd;
  width: 80%;
}
.th,
td {
  // width: 13%;
  text-align: center;
  padding: 10px;
}
.th {
  // background: #f2f2f2;
}
.td {
  // background: #f6f6f6;
}
</style>